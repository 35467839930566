<template lang="html">
  <section class="contact">
    <contactCard/>
  </section>
</template>

<script>
import contactCard from '@/components/contactCard'
export default {
  name: 'Contact',
  components: {
    contactCard,
  },
}
</script>

<style>
.contact{
  position: absolute;
  top: var(--nav-h);
  left: 0;
  right: 0;
  bottom: var(--footer-height);
}
</style>
