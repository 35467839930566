<template lang="html">
  <div class="card" @mousemove="setMouseCSSVars($event)">
    <div class="card-shadow">

    </div>
    <div class="card-wrapper" ref="card" @click="augment()">
      <div class="card-bg">
        <div class="card-info flex fj-center fa-center">
          <div class="qr-code" >
            <img class="qr-code-img"
            src="@/assets/img/qr-code-1.png"
            alt="qr-code for ynck.dev contact information"
            :class="{'--augment': augmented == true}">
            <!-- <svgIcon name="qr-code" class="qr-code-img" :class="{'--augment': augmented == true}"/> -->
          </div>
          <div class="card-info-text">
            <h1 class="card-info-title">Yannick</h1>
            <p class="card-info-desc">Web Developer</p>
            <p class="card-info-desc">ynck.dev@gmail.com</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
const root = document.querySelector(':root')
import svgIcon from '@/components/svgIcon'

export default {
  name: 'contactCard',
  data(){
    return{
      mouseX: 0,
      mouseY: 0,
      Xangle: 0,
      Yangle: 0,
      shadowBlur: 0,
      augmented: false,
    }
  },
  computed: {
  },
  methods: {
    augment(){
      if(this.augmented == false){
        this.augmented = true
      }
      else{
        this.augmented = false
      }
    },
    setMouseCSSVars($event){
      if($event){
        // console.log('mouse moved !')
        this.mouseX = event.clientX
      	this.mouseY = event.clientY

        this.Yangle = Math.round( ( this.mouseX  -(innerWidth/2)) * 75 / innerWidth )
        this.Xangle = Math.round( ( this.mouseY -(innerHeight/2) ) * 75 / innerHeight ) * (-1)
        this.shadowBlur = Math.round(Math.abs(this.Xangle / 4))

        root.style.setProperty('--Yangle', this.Yangle+'deg')
        root.style.setProperty('--Xangle', this.Xangle+'deg')
        root.style.setProperty('--shadow-blur', this.shadowBlur+'px')
      }
    }
  }
}
</script>

<style>
:root{
  --shadow-blur: ;
  --Xangle: ;
  --Yangle: ;
}
.card{
  position: absolute;
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  perspective: 1000px;
}
.card-wrapper{
  height: var(--card-height);
  min-height: 240px;
  max-height: 520px;
  width: var(--card-width);
  min-width: 240px;
  max-width: 520px;
  background-color: var(--color-prim);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  transform: rotateX(var(--Xangle)) rotateY(var(--Yangle));
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  cursor: pointer;
  transition: background-color var(--lin-norm);
}
.card-bg{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-shadow{
  position: relative;
  width: 384px;
  height: 2px;
  border-radius: 4px;
  filter: blur(calc(var(--shadow-blur) + 4px));
  -webkit-filter: blur(calc(var(--shadow-blur) + 4px));
  background-color: var(--color-darkester);
  transform: rotateY(var(--Yangle)) translateY(calc( var(--card-height) + 48px ) );
}
.--mode-light .card-shadow{
  background-color: var(--color-darker-80);
}
.card-info{
  height: 100%;
  width: 100%;
  color: var(--over-prim);
}
.card-info-text{
  margin-left: 24px;
}
@media screen and (max-width: 520px){
  .card-bg,
  .card-wrapper,
  .card-info{
    flex-direction: column;
  }
  .card-info-text{
    margin-left: 0;
    margin-top: 16px;
    text-align: center;
  }
  .card-shadow{
    display: none;
  }
}
.card-info-title,
.card-info-desc{
  transform: translateZ(24px);
  transition: transform var(--lin-norm);
}
.qr-code{
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qr-code-img{
  transform: translateZ(24px);
  height: 160px;
  width: 160px;
  cursor: pointer;
  pointer-events: none;
  transition: transform var(--lin-norm);
}
.--augment{
  transform: translateZ(240px);
}
</style>
